




























import { Component, Vue } from 'vue-property-decorator';
import { ProductDetailsContent } from '@/types/neuhaus-website/cms/pages/products';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageProductDetails Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-grid': () => import('@/components/cms/components/CMSComponentGrid.vue'),
        'cms-component-image': () => import('@/components/cms/components/CMSComponentImage.vue')
    }
})
export default class CMSPageProductDetails extends Vue {
    // variable with page content
    private pageContent: ProductDetailsContent = {
        id: -1,
        title: '',
        product: {
            id: -1,
            name: '',
            imageObj: undefined,
            imageUrl: '',
            informations: {
                id: -1,
                rows: []
            }
        }
    };

    private get productImageUrl(): string {
        return process.env.VUE_APP_API_URL + this.pageContent.product.imageUrl;
    }

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns ProductDetailsContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<ProductDetailsContent> {
        try {
            if (!this.$router.currentRoute.params.productId) throw null;

            const response = await axios.get<APIResponse<ProductDetailsContent>>(
                `/cms/pages/products/${this.$router.currentRoute.params.productId}`
            )

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }

}
